import { useState } from "react";
import Cookies from "js-cookie";

import {
  SESSION_COOKIE_NAME,
  THREAT_METRIX_MERCHANT_ORG_ID,
  BLOCK_MERCHANT_PROFILING,
} from "./const";
import { useThreatMetrix } from "./useThreatMetrix";

import { useOrganisation } from "src/queries/organisation";

/**
 * Wrapper component around ThreatMetrix to be used
 * inside the merchant-dashboard. Includes
 *  - getting the relevant profile id for dashboard users,
 *  - whether profiling disbaled for a given organisation
 *  - tracking for already profiled sessions.
 */
const ThreatMetrixProfiler = () => {
  const [sessions, setSessions] = useState<
    { id: string; profiled: boolean }[] | []
  >([]);

  const markAsProfiled = (id: string) =>
    setSessions([...sessions, { id, profiled: true }]);

  const id = Cookies.get(SESSION_COOKIE_NAME);
  const { startProfiling } = useThreatMetrix(
    id,
    THREAT_METRIX_MERCHANT_ORG_ID,
    markAsProfiled
  );
  const organisation = useOrganisation();
  const isProfilingDisabled = organisation?.features?.[BLOCK_MERCHANT_PROFILING]
    ? true
    : false;
  if (id && organisation && !isProfilingDisabled) {
    const sessionExists = sessions.find((session) => session.id === id);
    if (!sessionExists || !sessionExists.profiled) {
      startProfiling();
    }
  }
  return null;
};

export default ThreatMetrixProfiler;
