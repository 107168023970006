import {
  AvailableDebitSchemeEnum,
  OrganisationResource,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  P,
  ResponsiveValue,
  Space,
  SpaceScale,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Tooltip,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useFormContext } from "react-hook-form";

import { getInputErrorStatus } from "src/utils/inputValidation";
import { currencyToScheme } from "src/common/scheme";
import { Currency } from "src/common/currencies";
import { useOrganisation } from "src/queries/organisation";

interface PaymentReferenceFieldProps {
  fieldId?: "reference" | "payment_reference";
  spaceAbove?: ResponsiveValue<SpaceScale>;
  spaceBelow?: ResponsiveValue<SpaceScale>;
  selectedCurrency?: Currency;
  isRestricted?: boolean;
}

export interface FormWithPaymentReference {
  currency: Currency;
  reference?: string;
  payment_reference?: string;
}

const currencyToReferenceValidation: Record<Currency, number> = {
  [Currency.Usd]: 10,
  [Currency.Aud]: 30,
  [Currency.Dkk]: 15,
  [Currency.Eur]: 140,
  [Currency.Gbp]: 10,
  [Currency.Nzd]: 12,
  [Currency.Sek]: 11,
  [Currency.Cad]: 12,
};

const canSpecifyPaymentReference = (
  paymentCurrency: Currency,
  organisation?: OrganisationResource
): boolean => {
  if (!organisation) return false;
  const scheme = currencyToScheme[paymentCurrency];
  return !(
    scheme === AvailableDebitSchemeEnum.Bacs &&
    organisation?.is_using_shared_bacs_sun
  );
};

export const PaymentReferenceField: React.FC<PaymentReferenceFieldProps> = ({
  spaceAbove,
  spaceBelow,
  selectedCurrency,
  fieldId = "reference",
  isRestricted,
}) => {
  const { i18n } = useLingui();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormWithPaymentReference>();

  const organisation = useOrganisation();

  const currencyWatcher: Currency = selectedCurrency
    ? selectedCurrency
    : watch("currency");

  return canSpecifyPaymentReference(currencyWatcher, organisation) ? (
    <Box spaceAbove={spaceAbove} spaceBelow={spaceBelow}>
      <Field>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Label htmlFor={fieldId}>
            <Trans id="payment-fields.payment-reference">
              Payment Reference
            </Trans>
          </Label>
          <Space layout="inline" h={0.25} />
          <Tag
            size={TagSize.Sm}
            color={TagColor.Neutral}
            variant={TagVariant.Tinted}
          >
            <Trans id="payment-fields.optional">Optional</Trans>
          </Tag>
          <Tooltip
            tooltipId="payment_reference_info"
            placement="right"
            message={
              <P>
                <Trans id="payment-fields.displays-customers-statement">
                  This is displayed on your customer&apos;s bank statement
                </Trans>
              </P>
            }
          >
            <Box />
          </Tooltip>
        </Box>
        <Input
          data-testid="payment-reference"
          id={fieldId}
          placeholder={i18n._(
            t({
              id: "payment-fields.payment-ref-example",
              message: "e.g. GYM10",
            })
          )}
          status={getInputErrorStatus(!!errors[fieldId])}
          type="text"
          {...register(fieldId, {
            maxLength: currencyToReferenceValidation[currencyWatcher],
          })}
          disabled={isRestricted}
        />
        {errors[fieldId] ? (
          <Hint status={FormFieldStatus.Danger}>
            {errors?.[fieldId]?.message?.toString() ||
              errors?.[fieldId]?.types?.message?.toString() || (
                <Trans id="payment-fields.error-long-reference">
                  Payment reference should be less than{" "}
                  {currencyToReferenceValidation[currencyWatcher]} characters
                </Trans>
              )}
          </Hint>
        ) : null}
      </Field>
    </Box>
  ) : null;
};
